
import { defineComponent } from "vue";
import CodeHighlighter from "@/components/highlighters/CodeHighlighter.vue";
import { code7 } from "./data";

export default defineComponent({
  name: "grouping",
  data() {
    return {
      options: [
        {
          label: "Popular cities",
          options: [
            {
              value: "Shanghai",
              label: "Shanghai"
            },
            {
              value: "Beijing",
              label: "Beijing"
            }
          ]
        },
        {
          label: "City name",
          options: [
            {
              value: "Chengdu",
              label: "Chengdu"
            },
            {
              value: "Shenzhen",
              label: "Shenzhen"
            },
            {
              value: "Guangzhou",
              label: "Guangzhou"
            },
            {
              value: "Dalian",
              label: "Dalian"
            }
          ]
        }
      ],
      value: ""
    };
  },
  components: {
    CodeHighlighter
  },
  setup() {
    return {
      code7
    };
  }
});
